<template>
  <!-- 订单详情页面 -->
  <div id="appointDetail">
    <van-nav-bar title="订单详情" left-arrow @click-left="goLastPage" :border="false" />
    <product-infor buttonText="点击去预约" @buttonClick="goSubPage('reservation', $route.params.orderId)" />
  </div>
</template>
<script>
import ProductInfor from "@/components/order/ProductInfor.vue";
export default {
  data() {
    return {};
  },
  components: {
    ProductInfor,
  },
};
</script>
<style lang="less">
</style>